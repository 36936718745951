<template>
  <!--Footer component-->
  <div class="footer" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <transition><FooterLogo></FooterLogo></transition>
    <transition></transition><SocialIcons></SocialIcons></transition>
    <transition></transition><UserPolicies></UserPolicies></transition>
    <!-- <p class="address">Kerala Communicators Cable Limited Uzhaloor Temple Road, South Thoravu, Puthukkad,Thrissur-680301, Kerala.</p> -->
    <p class="cpyRgtPage">&copy; {{ date.getFullYear() }} KCCL. All rights reserved</p>
    <p class="app-verssion" :title="appVersion">v{{appVersion}}</p>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters, mapMutations } from "vuex";
import { _providerId, _projectName } from "@/provider-config.js";

export default {
  props: {
    showDeepHelp: {
      type: Boolean
    },
    showDeepAbout: {
      type: Boolean
    }
  },
  data() {
    return {
      date: new Date(),
      brand: null,
      showAboutPopup: false,
      showFaqPopup: false,
      showContactPopup: false,
      enableTicket: false,
      showAppShare: false,
      net5Logo: require("@/assets/icons/Net5.svg"),
      nammaflixLogo: require("@/assets/icons/nammaflix_logo.png"),
      providerUniqueId: _providerId,
      localDisplayLang: null,
      appVersion: null,
    };
  },
  watch: {
    showDeepHelp(val) {
      if (val) {
        this.showFaqPopup();
      }
    }
  },
  computed: {
    ...mapGetters(["appConfig", "getRtl"])
  },
  created() {
    this.brand =
      _projectName === "VLIVE"
        ? "vLive"
        : _projectName === "NET5"
        ? "Prime Originals"
        : _projectName === "NAMMAFLIX"
        ? "Anviton"
        : _projectName === "Noor Play"
        ? "Noor Play"
        : "";

    this.branchDeeplinks();
    eventBus.$on("show-contact-support", this.showContact);
    eventBus.$on("create-ticket-popup", this.showCreateTicket);
    eventBus.$on("show-share-app-popup", this.showAppReferral);
  },
  mounted() {
    this.appVersion = this.appConfig.appPlatform.appVersion;
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;

  },
  methods: {
    ...mapMutations(["setRtl"]),
    showAbout() {
      this.showAboutPopup = true;
      document.getElementById("body").style.overflowY = "hidden";
    },
    showFaq() {
      this.showFaqPopup = true;
      document.getElementById("body").style.overflowY = "hidden"; // will add later in mixin and reuse wherever necessary
    },
    showContact() {
      this.showFaqPopup = false;
      this.showContactPopup = true;
      document.getElementById("body").style.overflowY = "hidden";
    },
    hideContactPopup(state) {
      this.showContactPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleAboutPopup(state) {
      this.showAboutPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleFaqPopup(state) {
      this.showFaqPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    showCreateTicket(state) {
      this.showFaqPopup = false;
      this.enableTicket = state;
    },
    showAppReferral(state) {
      this.showAppShare = state;
      this.showAboutPopup = false;
    },
    toggleCreateTicket(state) {
      this.enableTicket = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleAppReferal(state) {
      this.showAppShare = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    branchDeeplinks() {
      if (this.showDeepHelp) {
        this.showFaq();
      } else if (this.showDeepAbout) {
        this.showAbout();
      }
    }
  },
  components: {
    About: () =>
      import(/* webpackChunkName: "about" */ "@/components/Popups/AboutUs.vue"),
    Faq: () =>
      import(
        /* webpackChunkName: "Faq" */ "@/components/Popups/Help/FAQ/Faq.vue"
      ),
    Contact: () =>
      import(
        /* webpackChunkName: "contact" */ "@/components/Popups/Help/ContactSupport/ContactSupport.vue"
      ),
    createTicket: () =>
      import(
        /* webpackChunkName: "createTicket" */ "@/components/Popups/ProfilePopups/createTicket.vue"
      ),
    imageItem: () =>
      import(
        /* Webpack ChunkName: "imageItem" */ "@/components/Templates/imageItem.vue"
      ),
    AppReferal: () =>
      import(
        /* webpackChunkName: "appReferal" */ "@/components/Popups/AppReferal.vue"
      ),
    FooterLogo: () => import( /* webpackChunkName: "footerLogo" */ "@/components/Footer/FooterLogo.vue"),
    SocialIcons: () => import( /* webpackChunkName: "socialIcons" */ "@/components/Footer/SocialIcons.vue"),
    UserPolicies: () => import( /* webpackChunkName: "userPolicies" */ "@/components/Footer/UserPolicies.vue"),
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./Footer.scss";

.footer {
  background: $clr-footer-bg;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  margin-top: 2rem;
  .app-verssion, .address, .cpyRgtPage {
    color: #FFFF;
    text-align: center;
    font-size: 0.8rem;
    padding: 0 1rem;
  }
  .cpyRgtPage {
    color: $clr-text;
  }

  .address {
    color: #919191
  }
}
@media screen and (min-width:820px) {
  .address {
    width: 55%;
  }
}
@media screen and (max-width:1023px) {
  .footer {
    margin-bottom: 5.1rem;
  }
}
</style>
